
interface FileTemplate {
  suffix: string,
  header: string,
  footer: string,
}

const fileTemplate1: FileTemplate = {
  suffix: '.xml',
  header: `<xml xmlns:s='uuid:BDC6E3F0-6DA3-11d1-A2A3-00AA00C14882'
  xmlns:dt='uuid:C2F41010-65B3-11d1-A29F-00AA00C14882'
  xmlns:rs='urn:schemas-microsoft-com:rowset'
  xmlns:z='#RowsetSchema'>
  <s:Schema id='RowsetSchema'>
    <s:ElementType name='row' content='eltOnly' rs:updatable='true'>
      <s:AttributeType name='c0' rs:name='Product Code' rs:number='1' rs:write='true'>
        <s:datatype dt:type='string' rs:dbtype='str' dt:maxLength='50'/>
      </s:AttributeType>
      <s:AttributeType name='ZonePrice' rs:number='2' rs:write='true'>
        <s:datatype dt:type='float' dt:maxLength='8' rs:precision='15' rs:fixedlength='true'/>
      </s:AttributeType>
      <s:extends type='rs:rowbase'/>
    </s:ElementType>
  </s:Schema>
  <rs:data>
    <rs:insert>`,
  footer: `    </rs:insert>
  </rs:data>
</xml>`,
}

const fileTemplate2: FileTemplate = {
  suffix: '~.xml',
  header: `<xml xmlns:s='uuid:BDC6E3F0-6DA3-11d1-A2A3-00AA00C14882'
  xmlns:dt='uuid:C2F41010-65B3-11d1-A29F-00AA00C14882'
  xmlns:rs='urn:schemas-microsoft-com:rowset'
  xmlns:z='#RowsetSchema'>
  <s:Schema id='RowsetSchema'>
    <s:ElementType name='row' content='eltOnly' rs:updatable='true'>
      <s:AttributeType name='RowN' rs:number='1' rs:write='true'>
        <s:datatype dt:type='int' dt:maxLength='4' rs:precision='10' rs:fixedlength='true'/>
      </s:AttributeType>
      <s:AttributeType name='ColsN' rs:number='2' rs:write='true'>
        <s:datatype dt:type='string' rs:dbtype='str' dt:maxLength='2147483647' rs:long='true'/>
      </s:AttributeType>
      <s:extends type='rs:rowbase'/>
    </s:ElementType>
  </s:Schema>
  <rs:data>
    <rs:insert>`,
  footer: `    </rs:insert>
  </rs:data>
</xml>
`,
}

const fileTemplate3: FileTemplate = {
  suffix: '^.xml',
  header: `<xml xmlns:s='uuid:BDC6E3F0-6DA3-11d1-A2A3-00AA00C14882'
  xmlns:dt='uuid:C2F41010-65B3-11d1-A29F-00AA00C14882'
  xmlns:rs='urn:schemas-microsoft-com:rowset'
  xmlns:z='#RowsetSchema'>
  <s:Schema id='RowsetSchema'>
    <s:ElementType name='row' content='eltOnly' rs:updatable='true'>
      <s:AttributeType name='SQLFieldName' rs:number='1' rs:write='true'>
        <s:datatype dt:type='string' rs:dbtype='str' dt:maxLength='2147483647' rs:long='true'/>
      </s:AttributeType>
      <s:AttributeType name='ColumnHeader' rs:number='2' rs:write='true'>
        <s:datatype dt:type='string' rs:dbtype='str' dt:maxLength='2147483647' rs:long='true'/>
      </s:AttributeType>
      <s:AttributeType name='ColumnWidth' rs:number='3' rs:write='true'>
        <s:datatype dt:type='float' dt:maxLength='8' rs:precision='15' rs:fixedlength='true'/>
      </s:AttributeType>
      <s:AttributeType name='FieldType' rs:number='4' rs:write='true'>
        <s:datatype dt:type='string' rs:dbtype='str' dt:maxLength='200'/>
      </s:AttributeType>
      <s:AttributeType name='FieldAlign' rs:number='5' rs:write='true'>
        <s:datatype dt:type='string' rs:dbtype='str' dt:maxLength='200'/>
      </s:AttributeType>
      <s:AttributeType name='Updatable' rs:number='6' rs:write='true'>
        <s:datatype dt:type='boolean' dt:maxLength='2' rs:fixedlength='true'/>
      </s:AttributeType>
      <s:extends type='rs:rowbase'/>
    </s:ElementType>
  </s:Schema>
  <rs:data>
    <rs:insert>
      <z:row SQLFieldName='Product Code' ColumnHeader='Product Code' ColumnWidth='10' FieldType='C' FieldAlign='L'
        Updatable='False'/>
      <z:row SQLFieldName='ZonePrice' ColumnHeader='Retail' ColumnWidth='10' FieldType='M' FieldAlign='R' Updatable='True'/>
    </rs:insert>
  </rs:data>
</xml>`,
  footer: ``,
}


function parseTSV(input: string) {
  return input.split('\n')
    .map(line => line.trim().split(/\s+/))
    .filter(row => row.length > 1)
}

// """\t\t<z:row c0='{}' ZonePrice='{}'/>\n""".format(code, price))
export function buildFile1(input: string) {
  return `${fileTemplate1.header}
${parseTSV(input).map(row => `      <z:row c0='${row[0]}' ZonePrice='${row[1]}'/>`).join('\n')}
${fileTemplate1.footer}`
}

//                f2.write("""\t\t<z:row RowN='{}' ColsN='1|'/>\n""".format(i + 1))
export function buildFile2(input: string) {
  return `${fileTemplate2.header}
${parseTSV(input).map((row, i) => `      <z:row RowN='${i + 1}' ColsN='1|'/>`).join('\n')}
${fileTemplate2.footer}`
}

export function buildFile3(input?: string) {
  return `${fileTemplate3.header}`
}

export function buildFilename(templateNum: number, basename: string) {
  let filename = basename
  switch (templateNum) {
    case 1:
      filename += fileTemplate1.suffix
      break
    case 2:
      filename += fileTemplate2.suffix
      break
    case 3:
      filename += fileTemplate3.suffix
      break
    default:
      throw new Error("Invalid templateNum")
  }
  return filename
}

export function buildFilePennylanePrice(input: string, dateString: string) {
  return `"FORMAT:183,2,[1],5[2],T3:[3]",,
${parseTSV(input).map((row, i) => {
    if (row[0] === undefined
      || row[1] === undefined
    ) throw new Error(`Invalid input on line ${i + 1}`)
    return `${row[0]},${row[1]},${dateString}`
  }).join('\n')}`
}

export function buildFilePennylanePriceCost(input: string, dateString: string) {
  return `"FORMAT:183,2,[1],5[2],6[3],T3:[4]",,,
${parseTSV(input).map((row, i) => {
    if (row[0] === undefined
      || row[1] === undefined
      || row[2] === undefined
    ) throw new Error(`Invalid input on line ${i + 1}`)
    return `${row[0]},${row[1]},${row[2]},${dateString}`
  }).join('\n')}`
}

export function downloadFile(filename: string, contents: string) {
  let element = document.createElement('a')
  element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(contents))
  element.setAttribute('download', filename)
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}