
export interface CannedResponseData {
  customerName?: string
  missingProducts?: string[]
  shippingOptions?: string[]
  couponCode?: string
  couponValue?: string
  trackingNumber?: string
}

export type CannedResponseInputData = Record<keyof CannedResponseData, string>
// const supplierShortageCovidOptionsResponse = (data: CannedResponseData) => {
//   const { customerName, missingProducts } = data
//   if (!customerName || customerName.length === 0) throw new Error("Enter customer name.")
//   if (!missingProducts || missingProducts.length === 0) throw new Error("Enter missing products.")

//   const missingProductsString = missingProducts.map(missingProduct => `- ${missingProduct}`).join('\n')

//   return `Dear ${customerName},

// Thank you for placing your order with Alive!

// Due to COVID-19, we sometimes run out of products or aren't able to get them in stock. Your order has been partially fulfilled and we are trying to source the following products that are out of stock:

// ${missingProductsString}

// We would hate for you to wait too long for the items you have ordered, which is why we would like to offer the following options:

// 1. Wait until the products listed above are available. Unfortunately, we cannot guarantee when that will happen or provide a timeline due to current extraordinary circumstances.

// 2. Send your partial order with the goods we have in stock. All of the goods EXCEPT the ones listed above will be sent to you. If the quantity above is not the total quantity you ordered that means that we have some of the product and it is ready to be shipped to you. E.g. If you ordered 2 bottles of vitamin C but only 1 is listed above means that we have 1 bottle in stock and it will be sent to you. If your order falls under the free shipping threshold because some goods are missing, and you choose this option, we will contact you to discuss the details. 

// 3. Cancel the order.

// Please reply to this email with one of the choices from above and we will take it from there. 

// Thank you for your patronage and we really appreciate you choosing Alive!

// Regards,

// Alive Team
// `
// }
const supplierShortageCancelItemNoShippingResponse = (data: CannedResponseData) => {
  const { customerName, missingProducts, couponCode, couponValue } = data
  if (!customerName || customerName.length === 0) throw new Error("Enter customer name.")
  if (!missingProducts || missingProducts.length === 0) throw new Error("Enter missing products.")

  const missingProductsString = missingProducts.map(missingProduct => `- ${missingProduct}`).join('\n')

  let couponCodeString = ""

  if (couponCode && couponValue) {
    couponCodeString = `
For your troubles, please accept this coupon code for ${couponValue} off your next order at www.ShopAlive.ca. You can enter this code during checkout.

Coupon Code: ${couponCode}
`
  }
  return `Dear ${customerName},

Unfortunately, due to a supplier shortage, we are unable to ship the following product${missingProducts.length > 1 ? 's' : ''}:

${missingProductsString}

Because of this, the item${missingProducts.length > 1 ? 's' : ''} ${missingProducts.length > 1 ? 'have' : 'has'} been removed from your order and you will not be charged for this.

However, because of this, your order no longer qualifies for free shipping. We can substitute for another product of your choice or the shipping cost for this order will be an extra $12.95. Please let us know what you would like to do by replying to this email.
${couponCodeString}
We're very sorry for any inconvenience caused. Thank you for your understanding, and have a great day!`
}
const supplierShortageCancelItemResponse = (data: CannedResponseData) => {
  const { customerName, missingProducts, couponCode, couponValue } = data
  if (!customerName || customerName.length === 0) throw new Error("Enter customer name.")
  if (!missingProducts || missingProducts.length === 0) throw new Error("Enter missing products.")

  const missingProductsString = missingProducts.map(missingProduct => `- ${missingProduct}`).join('\n')

  let couponCodeString = ""

  if (couponCode && couponValue) {
    couponCodeString = `
For your troubles, please accept this coupon code for ${couponValue} off your next order at www.ShopAlive.ca. You can enter this code during checkout.

Coupon Code: ${couponCode}
`
  }
  return `Dear ${customerName},

Unfortunately, due to a supplier shortage, we are unable to ship the following product${missingProducts.length > 1 ? 's' : ''}:

${missingProductsString}

Because of this, the item${missingProducts.length > 1 ? 's' : ''} ${missingProducts.length > 1 ? 'have' : 'has'} been removed from your order and you will not be charged for this.
${couponCodeString}
We're very sorry for any inconvenience caused. If you have any questions, please let us know by replying to this email. Thank you for your understanding, and have a great day!`
}
const supplierShortageCancelOrder = (data: CannedResponseData) => {
  const { customerName, missingProducts, couponCode, couponValue } = data
  if (!customerName || customerName.length === 0) throw new Error("Enter customer name.")
  if (!missingProducts || missingProducts.length === 0) throw new Error("Enter missing products.")

  const missingProductsString = missingProducts.map(missingProduct => `- ${missingProduct}`).join('\n')

  let couponCodeString = ""

  if (couponCode && couponValue) {
    couponCodeString = `
For your troubles, please accept this coupon code for ${couponValue} off your next order at www.ShopAlive.ca. You can enter this code during checkout.

Coupon Code: ${couponCode}
`
  }

  return `Dear ${customerName},

Unfortunately, due to a supplier shortage, we are unable to ship the following product${missingProducts.length > 1 ? 's' : ''}:

${missingProductsString}

Because of this, the order has been cancelled and your payment will be refunded.
${couponCodeString}
If you have any questions, please let us know by replying to this email. Thank you for your understanding, and have a great day!`

}
const supplierShortageShipPartialResponse = (data: CannedResponseData) => {
  const { customerName, missingProducts, couponCode, couponValue } = data
  if (!customerName || customerName.length === 0) throw new Error("Enter customer name.")
  if (!missingProducts || missingProducts.length === 0) throw new Error("Enter missing products.")

  const missingProductsString = missingProducts.map(missingProduct => `- ${missingProduct}`).join('\n')

  let couponCodeString = ""

  if (couponCode && couponValue) {
    couponCodeString = `
For your troubles, please accept this coupon code for ${couponValue} off your next order at www.ShopAlive.ca. You can enter this code during checkout.

Coupon Code: ${couponCode}
`
  }
  return `Dear ${customerName},

Unfortunately, due to a supplier shortage, we are still waiting for the following product${missingProducts.length > 1 ? 's' : ''}:

${missingProductsString}

Because of this, the item${missingProducts.length > 1 ? 's' : ''} will be shipped in the next few business days when we receive the items. Any other items in your order will be shipped first.
${couponCodeString}
We're very sorry for any inconvenience caused. If you have any questions, please let us know by replying to this email. Thank you for your understanding, and have a great day!`
}
const supplierShortageShipAllLaterResponse = (data: CannedResponseData) => {
  const { customerName, missingProducts, couponCode, couponValue } = data
  if (!customerName || customerName.length === 0) throw new Error("Enter customer name.")
  if (!missingProducts || missingProducts.length === 0) throw new Error("Enter missing products.")

  const missingProductsString = missingProducts.map(missingProduct => `- ${missingProduct}`).join('\n')

  let couponCodeString = ""

  if (couponCode && couponValue) {
    couponCodeString = `
For your troubles, please accept this coupon code for ${couponValue} off your next order at www.ShopAlive.ca. You can enter this code during checkout.

Coupon Code: ${couponCode}
`
  }
  return `Dear ${customerName},

Unfortunately, due to a supplier shortage, we are still waiting for the following product${missingProducts.length > 1 ? 's' : ''}:

${missingProductsString}

Because of this, your order has been delayed a few business days until we receive the items.
${couponCodeString}
We're very sorry for any inconvenience caused. If you have any questions, please let us know by replying to this email. Thank you for your understanding, and have a great day!`
}
function addShippingOptionSuffix(shippingOption: string) {
  const shippingOptionsSuffixes: Record<string, string> = {
    "smallpacket": "(No Tracking)",
    "internationalparcel": "(No Tracking)",
  }
  let newOption = shippingOption
  for (let suffix of Object.keys(shippingOptionsSuffixes)) {
    if (shippingOption.toLowerCase().replace(/\s/g, "").includes(suffix)) {
      return newOption + " " + shippingOptionsSuffixes[suffix]
    }
  }
  return newOption
}
function removeGuaranteed(shippingOption: string) {
  return shippingOption.replace(/\s?\(?Guaranteed\)?/g, "")
}
function removeTM(shippingOption: string) {
  return shippingOption.replace(/(\S)TM/g, "$1")
}
const shippingOptionsResponse = (data: CannedResponseData) => {
  const { customerName, shippingOptions } = data
  if (!customerName || customerName.length === 0) throw new Error("Enter customer name.")
  if (!shippingOptions || shippingOptions.length === 0) throw new Error("Enter shipping options.")

  return `Dear ${customerName},

You have the following shipping options from Canada Post:

${shippingOptions.map((shippingOption, i) => `${i + 1}. ${removeTM(removeGuaranteed(addShippingOptionSuffix(shippingOption)))}`).join('\n')}

Please note that not all shipping options include tracking information.

For more information on shipping times, please visit the following page: https://www.shopalive.ca/shipping-options-details/

In order to make a selection, simply reply to this email with one of the selections above and your order will be updated.

Thank you for your time, and have a great day!`
}
const incorrectBillingAddressResponse = (data: CannedResponseData) => {
  const { customerName } = data
  if (!customerName || customerName.length === 0) throw new Error("Enter customer name.")
  return `Dear ${customerName},

We've noticed that you have recently tried to place an order, but it didn't go through.

In order to prevent fraud, our system requires that the billing address and postal code match the credit card used.

If you would like to try ordering again, ensure that the address and postal code is the same as registered with your credit card.

You can ship to a place other than your billing address by selecting "Ship to a different address" during checkout.

We hope this helps!

If you require any further assistance or have any questions, please feel free to reply to this email, or call us at 1-866-220-7218 ext 316 (leave a message and a callback number if we're unavailable, we'll call you back!).

Thank you for your time, and have a great day!`
}
// const failedWechatPayResponse = (data: CannedResponseData) => {
//   const { customerName } = data
//   if (!customerName || customerName.length === 0) throw new Error("Enter customer name.")
//   return `Dear ${customerName},

// We've noticed that you have recently tried to place an order, but it didn't go through.

// They payment method selected was "WeChat Pay", which requires a special app on your phone to pay.

// If you intended to pay by credit card, you would not want "WeChat Pay", and you can select "Credit Card" on the checkout page instead. Please feel free to re-order anytime with "Credit Card" selected during checkout, instead of WeChat pay.

// If you intend to use WeChat Pay, it appears the QR code was not scanned for payment.

// Otherwise, if you do not wish to continue with this order at all, you may simply disregard this email.

// We hope this helps!

// If you require any further assistance or have any questions, please feel free to reply to this email, or call us at 1-866-220-7218 ext 316 (leave a message and a callback number if we're unavailable, we'll call you back!).

// Thank you for your time, and have a great day!`
// }
const volumeDiscountResponse = (data: CannedResponseData) => {
  const { customerName } = data
  if (!customerName || customerName.length === 0) throw new Error("Enter customer name.")
  return `Dear ${customerName},

Unfortunately, we do not offer the volume discounts online, such as the buy 6, get 1 free that the stores have. This is because our pricing for the online shop is slightly different.

We do strive to keep the product pricing as similar as possible, but the promotions are different online and in-store. For example, online we have free shipping on orders over $60, as well as pre-discounted prices we mostly list as our regular online price. You may find that some of the items online are cheaper than in-store and vice versa!

If you haven't already, you can sign up for our ShopAlive.ca newsletters to stay informed about promotions exclusive to ShopAlive.ca as well.

Thank you for taking the time to contact us. I hope this information is helpful. If there are any other questions, please feel free to reply. Have a great day!`
}
const cadResponse = (data: CannedResponseData) => {
  const { customerName } = data
  if (!customerName || customerName.length === 0) throw new Error("Enter customer name.")
  return `Dear ${customerName},

The 20% Customer Appreciation Day sale is only applicable in store, as they have a slightly different pricing structure than online at ShopAlive.ca. One notable difference is that in-store many products have sale pricing in which the 20% discount does not apply to, but online we list the already on-sale prices as regular online prices for simplicity.

On average, we strive to keep the total pricing as similar as possible to the stores, but with free shipping and dynamic store sale pricing involved, the pricing for in-store and online will be slightly different. You may actually find that some products come out cheaper online after the 10% discount, compared to products in-store with certain restrictions on the 20% Customer Appreciation day, and vice versa!

If you haven't already, you can sign up for our ShopAlive.ca newsletters to stay informed about promotions exclusive to ShopAlive.ca as well.

I hope this is helpful, and I do apologize for any confusions caused! If you have any further questions please don't hesitate to contact us. Thank you, and have a great day!`
}
// const covidSuspendedLocation = (data: CannedResponseData) => {
//   const { customerName } = data
//   if (!customerName || customerName.length === 0) throw new Error("Enter customer name.")
//   return `Dear ${customerName},

// Unfortunately, we are currently unable to ship your order to you. Canada Post (our primary shipping method) has suspended shipment to your shipping location due to COVID-19. For more information, please visit the following pages:

// https://www.canadapost.ca/cpc/en/our-company/news-and-media/service-alerts.page

// https://www.canadapost.ca/assets/pdf/aboutus/servicealerts/destinations-to-which-service-is-suspended.pdf

// If you prefer, we can ship this package to different address, as the current location is unavailable. If not, we can go ahead and cancel the order. You will not be charged on your credit card and the authorization will be removed (and usually will disappear from your online statement).
// `
// }
const ketoScamWithOrderNum = (data: CannedResponseData) => {
  const { customerName } = data
  if (!customerName || customerName.length === 0) throw new Error("Enter customer name.")
  return `Dear ${customerName},

Unfortunately, we believe that you are looking for a different company, as the order number you provided does not come from ShopAlive.ca or our company Alive Health Centre.

We have recently received many complaints about similar charges to other customer accounts, but I assure you that this did not come from our company. (Another company with the "Alive" name, perhaps?)

Our only suggestion is to try to contact the actual company that made the charges, or to contact your credit card or bank about this charge.

If you have any web links or emails from your previous order, we would love to see them and try to find out who has been overcharging people.

I'm sorry that we couldn't be of any more help.I hope this gets resolved for you soon.`
}
const ketoScamByWithEmail = (data: CannedResponseData) => {
  const { customerName } = data
  if (!customerName || customerName.length === 0) throw new Error("Enter customer name.")
  return `Dear ${customerName},

Unfortunately, we believe that you are looking for a different company, as we cannot find your name, email, or phone number in our system at ShopAlive.ca or our company Alive Health Centre.

We have recently received many complaints about similar charges to other customer accounts, but I assure you that this did not come from our company. (Another company with the "Alive" name, perhaps?)

Our only suggestion is to try to contact the actual company that made the charges or to contact your credit card or bank about this charge and have it disputed.

If you have any links or emails from your previous order, we would love to see them and try to find out who has been overcharging people.

I'm sorry that we couldn't be of any more help. I hope this gets resolved for you soon.`
}
const canadaPostStrikeNoRoute = (data: CannedResponseData) => {
  const { customerName } = data
  if (!customerName || customerName.length === 0) throw new Error("Enter customer name.")
  return `Dear ${customerName},

We're sorry, but due to the Canada Post strike, we are unable to ship your order at this time. Even with our current alternative shipping methods, we are unable to ship to your location.

We can hold your order until the strike is over, or we can cancel it and refund your payment if you let us know by replying to this email.

If we do not hear back from you, your order will be held until the strike is over.

More information can be found using this link:
https://www.shopalive.ca/canada-post-on-strike/

We apologize for the inconvenience and thank you for your understanding.`
}
const canadaPostStrikeHeldAtPostOffice = (data: CannedResponseData) => {
  const { customerName, trackingNumber } = data

  if (!customerName || customerName.length === 0) throw new Error("Enter customer name.")
  const trackingNumberString = trackingNumber ? `Your Canada Post tracking number is: ${trackingNumber}\n\nYou can use the following link to view the progress:\n\nhttps://www.canadapost.ca/trackweb/en#/search?searchFor=${trackingNumber}` : "Unfortunately, we do not have a tracking number for your order at this time."
  return `Dear ${customerName},

We're sorry, but due to the Canada Post strike, we are experiencing delays in shipping your order. Your order has already been shipped, but it may take longer than usual to arrive.

Unfortunately, this is out of our control, and we are unable to provide an estimated delivery date at this time.

${trackingNumberString}

We apologize for the inconvenience and thank you for your understanding.


For more information about the strike, please visit the following page:
https://www.canadapost-postescanada.ca/cpc/en/our-company/news-and-media/corporate-news/negotiations-list.page
`
}


export interface CannedResponse {
  key: string
  label: string
  fields: (keyof CannedResponseData)[]
  generator: any
}
export const cannedResponses: CannedResponse[] = [
  {
    key: 'shippingOptions',
    label: 'Shipping Options',
    fields: ['customerName', 'shippingOptions'],
    generator: shippingOptionsResponse,
  },
  // {
  //   key: 'supplierShortageCovidOptions',
  //   label: 'Supplier Shortage - Covid Options',
  //   fields: ['customerName', 'missingProducts'],
  //   generator: supplierShortageCovidOptionsResponse,
  // },
  {
    key: 'supplierShortageCancelItemNoShipping',
    label: 'Supplier Shortage - Cancel Item, No Free Shipping',
    fields: ['customerName', 'missingProducts', 'couponCode', 'couponValue'],
    generator: supplierShortageCancelItemNoShippingResponse,
  },
  {
    key: 'supplierShortageCancelItem',
    label: 'Supplier Shortage - Cancel Item',
    fields: ['customerName', 'missingProducts', 'couponCode', 'couponValue'],
    generator: supplierShortageCancelItemResponse,
  },
  {
    key: 'supplierShortageCancelOrder',
    label: 'Supplier Shortage - Cancel Order',
    fields: ['customerName', 'missingProducts', 'couponCode', 'couponValue'],
    generator: supplierShortageCancelOrder,
  },
  {
    key: 'supplierShortageShipLater',
    label: 'Supplier Shortage - Ship Partial',
    fields: ['customerName', 'missingProducts', 'couponCode', 'couponValue'],
    generator: supplierShortageShipPartialResponse,
  },
  {
    key: 'supplierShortageShipAllLater',
    label: 'Supplier Shortage - Ship All Later',
    fields: ['customerName', 'missingProducts', 'couponCode', 'couponValue'],
    generator: supplierShortageShipAllLaterResponse,
  },
  {
    key: 'incorrectBillingAddress',
    label: 'Incorrect Billing Address',
    fields: ['customerName'],
    generator: incorrectBillingAddressResponse,
  },
  // {
  //   key: 'failedWechatPay',
  //   label: 'Failed WeChat Pay',
  //   fields: ['customerName'],
  //   generator: failedWechatPayResponse,
  // },
  {
    key: 'cad',
    label: 'CAD Online Difference',
    fields: ['customerName'],
    generator: cadResponse,
  },
  {
    key: 'volumeDiscount',
    label: 'Volume Discount Online',
    fields: ['customerName'],
    generator: volumeDiscountResponse,
  },
  // {
  //   key: 'covidSuspendedLocation',
  //   label: 'COVID-19 Suspended Location',
  //   fields: ['customerName'],
  //   generator: covidSuspendedLocation,
  // },
  {
    key: 'ketoScamWithOrderNum',
    label: 'Keto Scam w/ Order Num',
    fields: ['customerName'],
    generator: ketoScamWithOrderNum,
  },
  {
    key: 'ketoScamByWithEmail',
    label: 'Keto Scam w/ Email',
    fields: ['customerName'],
    generator: ketoScamByWithEmail,
  },
  {
    key: 'canadaPostStrikeNoRoute',
    label: 'Canada Post Strike - No Route',
    fields: ['customerName'],
    generator: canadaPostStrikeNoRoute,
  },
  {
    key: 'canadaPostStrikeHeldAtPostOffice',
    label: 'Canada Post Strike - Held at Post Office',
    fields: ['customerName', 'trackingNumber'],
    generator: canadaPostStrikeHeldAtPostOffice,
  },
]
