/** Sequentially opens orders in browser tabs with a delay to prevent flooding the server. */
export function popOpenOrders(orderNums: string[]) {
  const openDelay = 200
  const orderNumsTemp = [...orderNums]
  let i = 0
  const popOpen = () => {
    if (i >= orderNumsTemp.length) return
    const orderNum = orderNums[i]
    window.open(
      `https://www.shopalive.ca/wp-admin/post.php?post=${orderNum}&action=edit`,
      '_blank'
    )
    // newWindow?.blur()
    // window.focus()
    setTimeout(popOpen, openDelay)
    i++
  }
  popOpen()
}


