import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  outputContainer: {
    height: "100%",
  },
  outputTextarea: {
    width: "100%",
    height: "100%",
  },
})


type OutputProps = {
  label: string,
  value: string,
}

export default function Output({ label, value }: OutputProps) {
  const classes = useStyles()
  return <Grid container
    direction="column"
    alignItems="stretch"
    className={classes.outputContainer}>
    <Grid item>
      {label}
    </Grid>
    <Grid item xs>
      <textarea
        className={classes.outputTextarea}
        value={value} onChange={e => { }} />
    </Grid>
  </Grid>
}