import React, { useState, useEffect, useCallback } from "react"
import { Button, Grid, Container, Typography, TextField } from "@material-ui/core"
import moment from "moment-timezone"

import { buildFile1, buildFile2, buildFile3, buildFilePennylanePrice, buildFilename, downloadFile, buildFilePennylanePriceCost } from "../../../lib/bulkPriceChange"
import Output from "./Output"
import StyledPaper from "../../StyledPaper"


const TEST_INPUT = `61281	32.99	14.25
87434	19.99	8.775
87309	11.99	5.25
87849	23.99	10.5375
12463	13.99	6.0375
82222	31.99	13.8375
87483	9.99	4.275
89034	28.99	12.4875
12462	19.99	8.5875
12396	19.99	8.5875
71654	23.99	10.35
12393	40.99	17.775
61752	60.99	26.5125
62070	28.99	12.675
12472	25.99	11.325
61282	15.99	6.6375
88284	15.99	6.6375
12470	16.99	7.425
12262	8.99	3.9
83312	29.99	12.8625
`

const basenames = [
  { label: "BC", basename: "bc-automated-update" },
  { label: "AB", basename: "ab-automated-update" },
  { label: "ON", basename: "on-automated-update" },
]

export default function BulkPriceChange() {
  const [input, setInput] = useState(TEST_INPUT)
  const [output1, setOutput1] = useState("")
  const [output2, setOutput2] = useState("")
  const [output3, setOutput3] = useState("")
  const [outputPennylanePrice, setOutputPennylanePrice] = useState("")
  const [outputPennylanePriceCost, setOutputPennylanePriceCost] = useState("")
  const [dateString, setDateString] = useState(`${moment().format('YYYY-MM-DD')} HO`)

  useEffect(() => {
    setOutput1(buildFile1(input))
    setOutput2(buildFile2(input))
    setOutput3(buildFile3(input))
    try {
      setOutputPennylanePrice(buildFilePennylanePrice(input, dateString))
    } catch (err: any) {
      setOutputPennylanePrice(err)
    }
    try {
      setOutputPennylanePriceCost(buildFilePennylanePriceCost(input, dateString))
    } catch (err: any) {
      setOutputPennylanePriceCost(err)
    }
  }, [dateString, input])

  const handleBasenameClick = useCallback((e, fileNum, basename) => {
    let output
    switch (fileNum) {
      case 1:
        output = output1
        break
      case 2:
        output = output2
        break
      case 3:
        output = output3
        break
      default:
        throw new Error("Invalid fileNum")
    }
    downloadFile(buildFilename(fileNum, basename), output)
  }, [output1, output2, output3])

  const handlePennylanePriceClick = useCallback(e => {
    downloadFile('terms.plu price.csv', outputPennylanePrice.replace(/\n/g, '\r\n'))
  }, [outputPennylanePrice])

  const handlePennylanePriceCostClick = useCallback(e => {
    downloadFile('terms.plu pricecost.csv', outputPennylanePriceCost.replace(/\n/g, '\r\n'))
  }, [outputPennylanePriceCost])



  return <Container>
    <Typography variant="h3">Bulk Price Change</Typography>
    <Typography variant="subtitle1">File Generator</Typography>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item>
            <StyledPaper>
              <div>
                {'TSV Input'}
              </div>
              <div>
                <textarea
                  rows={10}
                  cols={40}
                  value={input} onChange={e => setInput(e.target.value)} />
              </div>
            </StyledPaper>
          </Grid>
          <Grid item>
            <StyledPaper>
              <div>
                <TextField
                  label="Date String"
                  variant="outlined"
                  size="small"
                  value={dateString}
                  onChange={e => setDateString(e.target.value)} />
              </div>
            </StyledPaper>
          </Grid>
          <Grid item>
            <StyledPaper>
              <span>Download as:</span>
              <div>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={handlePennylanePriceClick}>
                  Pennylane Price</Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={handlePennylanePriceCostClick}>
                  Pennylane Price+Cost</Button>
              </div>
              <div>
                {basenames.map(row => <Button key={row.basename}
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={e => handleBasenameClick(e, 1, row.basename)}>
                  {row.label} 1</Button>)}
              </div>
              <div>
                {basenames.map(row => <Button key={row.basename}
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={e => handleBasenameClick(e, 2, row.basename)}>
                  {row.label} 2</Button>)}
              </div>
              <div>
                {basenames.map(row => <Button key={row.basename}
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={e => handleBasenameClick(e, 3, row.basename)}>
                  {row.label} 3</Button>)}
              </div>
              <div>Rename Aralco _.xml files to ~.xml</div>
            </StyledPaper>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <StyledPaper>
          <Grid container style={{ minHeight: 300 }} spacing={1}>
            <Grid item xs>
              <Output label="'terms.plu price.csv'" value={outputPennylanePrice} />
            </Grid>
            <Grid item xs>
              <Output label="'terms.plu pricecost.csv'" value={outputPennylanePriceCost} />
            </Grid>
            <Grid item xs>
              <Output label="Aralco 1 - .xml" value={output1} />
            </Grid>
            <Grid item xs>
              <Output label="Aralco 2 - ~.xml" value={output2} />
            </Grid>
            <Grid item xs>
              <Output label="Aralco 3 - ^.xml" value={output3} />
            </Grid>
          </Grid>
        </StyledPaper>
      </Grid>
    </Grid>
  </Container>
}