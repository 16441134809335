import React from 'react'
import CannedResponses from './CannedResponses'
import PopOpener from './PopOpener'
import { Grid, Container, Typography } from '@material-ui/core'
import StyledPaper from '../../StyledPaper'


export default function OnlineOrders() {
  return <Container>
    <Typography variant="h3">Online Orders</Typography>
    <Grid container spacing={1}>
      <Grid item xs={12} md>
        <StyledPaper>
          <PopOpener />
        </StyledPaper>
      </Grid>
      <Grid item xs={12} md>
        <StyledPaper>
          <CannedResponses />
        </StyledPaper>
      </Grid>
    </Grid>
  </Container>
}