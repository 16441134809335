import React, { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import { Container, Typography, makeStyles } from "@material-ui/core"
import { v4 as uuidv4 } from "uuid"
import { produce } from "immer"

import { generateImageData, newExtension } from "../../../lib/productPhoto"


const useStyles = makeStyles(theme => ({
  dropzoneRoot: {
    borderWidth: 1,
    borderColor: theme.palette.text.secondary,
    borderStyle: "dashed",
    padding: 15,
    minHeight: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.secondary,
  },
  imgJpgPreview: {
    border: "1px solid black",
    minWidth: 300,
    minHeight: 300,
    width: 300,
    height: 300,
  },
}))


export default function ProductPhoto() {
  const classes = useStyles()
  type ImageItem = {
    filename: string
    processing: boolean
    error: boolean
    src: string | null
    key: string
  }
  const [imageData, setImageData] = useState<ImageItem[]>([])
  // const [jpgSrc, setJpgSrc] = useState("")
  // const [filename, setFilename] = useState("blank.jpg")
  const [processing, setProcessing] = useState(false)
  // const [processingError, setProcessingError] = useState(false)

  const [isBundle, setIsBundle] = useState(false)

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (processing) return
    setProcessing(true)
    let imageData: ImageItem[] = acceptedFiles.map(file => {
      return {
        filename: file.name,
        processing: true,
        error: false,
        src: null,
        key: uuidv4(),
      }
    })
    setImageData(imageData)

    for (let i = 0; i < acceptedFiles.length; i++) {
      try {
        const file = acceptedFiles[i]
        //     setJpgSrc(await generateImageData(file))
        //     setFilename(file.name)
        const src = await generateImageData(file, { bundle: isBundle })
        imageData = produce(imageData, draft => {
          draft[i].src = src
          draft[i].processing = false
          draft[i].error = false
        })
      } catch (err) {
        console.log(err)
        imageData = produce(imageData, draft => {
          draft[i].processing = false
          draft[i].error = true
        })
      }
      setImageData(imageData)
    }
    setProcessing(false)
  }, [isBundle, processing])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const handleDownloadAllClick = useCallback(async () => {
    const wait = async (ms: number) => new Promise(resolve => setTimeout(resolve, ms))
    // Create multiple downloads in chrome
    for (const imageItem of imageData) {
      if (imageItem.src) {
        const link = document.createElement("a")
        link.href = imageItem.src
        link.download = newExtension(imageItem.filename)
        link.click()
        await wait(100) // If too fast, chrome will generally download only up to 10 files
      }
    }
  }, [imageData])

  return <Container>
    <Typography variant="h3">Product Photo</Typography>

    <input type="checkbox" id="check-bundle" checked={isBundle} onChange={e => setIsBundle(e.currentTarget.checked)} />
    <label htmlFor="check-bundle"> Bundle</label>

    <div {...getRootProps()} className={classes.dropzoneRoot}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <div>Drop files here...</div>
      ) :
        <div>Drag and drop images here, or click to select files.</div>
      }
    </div>
    {imageData.length > 0 && imageData.every(imageItem => imageItem.src) &&
      <button onClick={handleDownloadAllClick}>Download All</button>
    }
    <div style={{ display: "flex", alignItems: "start", flexWrap: "wrap", }}>
      {imageData.map((imageItem, i) => {
        const { processing, error, src, filename } = imageItem
        return <div
          key={i + " " + filename}
          style={{ display: "inline-block", padding: 5 }}>
          <div><b>{filename}</b></div>
          {processing && <div>Processing...</div>}
          {error && <div>Error processng.</div>}
          {src &&
            <div>
              <div>
                <a
                  download={newExtension(filename)}
                  href={src}>
                  Download
                </a>
              </div>
              <div>JPG Preview (1200x1200)</div>
              <div>
                <img
                  className={classes.imgJpgPreview}
                  src={src}
                  alt={filename}
                  title={filename}
                />
              </div>
            </div>
          }
        </div>
      })}
    </div>
  </Container>
}