

/** Splits and cleans an input string with predefined separators. */
export function inputStringToList(input: string, splitSpaces = true) {
  const splitReString = splitSpaces ? "[\\s,;]" : "[\\t\\n,;]"
  const splitRe = new RegExp(splitReString)
  return input.split(splitRe).map(token => token.trim()).filter(token => token !== "")
}

export function linesToList(input: string) {
  return input.split("\n").map(token => token.trim()).filter(token => token !== "")
}