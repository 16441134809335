import React from 'react'
import { Paper, makeStyles } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  }
}))

export default function StyledPaper({ ...props }) {
  const classes = useStyles()
  return <Paper className={classes.root} {...props} />
}