import axios from 'axios'

interface LoginInfo {
  userData: any,
  jwt: string | null,
}
export const loginInfo: LoginInfo = {
  userData: null,
  jwt: null,
}

const LOCALSTORAGE_KEY = 'net.alivehealth.gen.jwt'

const localStorageJwt = localStorage.getItem(LOCALSTORAGE_KEY)
if (localStorageJwt) {
  console.log("Using localStorage jwt")
  loginInfo.jwt = localStorageJwt
}

export async function login(username: string, password: string, remember: boolean = false) {
  const res = await axios.post('/login', { username, password, remember })
  if (res.data.status === 'success') {
    loginInfo.jwt = res.data.token
    if (remember) {
      localStorage.setItem(LOCALSTORAGE_KEY, res.data.token)
    }
    else {
      localStorage.removeItem(LOCALSTORAGE_KEY)
    }
  }
}
