import Jimp from "jimp/browser/lib/jimp"

async function loadImage(url: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve(img)
    }
    img.onerror = () => {
      reject("Failed to load image")
    }
  })
}

/** Reads image file as a data URL */
async function readImageFile(file: File) {
  return new Promise<string>((resolve, reject) => {
    try {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result as string)
      }
      reader.readAsDataURL(file)
    } catch (err) {
      reject(err)
    }
  })
}


type GenerateImageDataOptions = {
  bundle?: boolean
}
export async function generateImageData(file: File, options: GenerateImageDataOptions = {}) {
  const image = await Jimp.read(await readImageFile(file))
  image.background(0xffffffff)
  image.autocrop({ tolerance: 0.01 })
  image.contain(1120, 1120)
  const framed = new Jimp(1200, 1200)
  framed.background(0xffffffff)
  framed.composite(image, 40, 40, { mode: Jimp.BLEND_DESTINATION_OVER, opacityDest: 1, opacitySource: 1 })
  if (options.bundle) {
    framed.composite(await Jimp.read("./assets/get-the-bundle-overlay.png"), 0, 0)
  }
  framed.quality(80)

  return await framed.getBase64Async(Jimp.MIME_JPEG)
}


/** Replaces the extension of a filename */
export function newExtension(filename: string, extension: string = '.jpg') {
  return filename.replace(/\.[^.]+$/, '') + extension
}