import React from 'react'

import LoginForm from '../../LoginForm'
import { Container } from '@material-ui/core'


export default function Login() {
  return <Container>
    <LoginForm />
  </Container>
}