import React, { useState, useMemo, useCallback, useRef } from 'react'
import { TextField, Typography, Grid, MenuItem, Button, IconButton } from '@material-ui/core'
import { linesToList } from '../../../lib/util'
import { cannedResponses, CannedResponse, CannedResponseData, CannedResponseInputData } from "../../../lib/cannedResponses"
import { Delete } from '@material-ui/icons'


const defaultResponseInputData = {
  customerName: "",
  missingProducts: "",
  shippingOptions: "",
  couponCode: "",
  couponValue: "",
  trackingNumber: "",
}

export default function CannedResponses() {
  const [selectedCannedResponse, setSelectedCannedResponse] = useState<CannedResponse>(cannedResponses[0])
  const [responseError, setResponseError] = useState<string | null>(null)
  const responseInputRef = useRef<HTMLTextAreaElement | null>(null)
  const [responseInputData, setResponseDataInput] = useState<CannedResponseInputData>(defaultResponseInputData)

  const responseData = useMemo<CannedResponseData>(() => ({
    ...responseInputData,
    missingProducts: linesToList(responseInputData.missingProducts),
    shippingOptions: linesToList(responseInputData.shippingOptions),
  }), [responseInputData])

  const handleSelectedCannedResponseChange = useCallback(e => {
    setSelectedCannedResponse(
      cannedResponses.find(
        cannedResponse =>
          cannedResponse.key === e.target.value)
      || cannedResponses[0]
    )
  }, [])

  const handleClearClick = useCallback(e => {
    setResponseDataInput({ ...defaultResponseInputData })
  }, [])

  const handleCopyClick = useCallback(e => {
    if (!responseInputRef.current) return
    responseInputRef.current.select()
    document.execCommand("copy")
  }, [])

  const handleResponseInputChange = useCallback((key: keyof CannedResponseInputData, value: string) => {
    setResponseDataInput(data => ({ ...data, [key]: value }))
  }, [])

  const cannedResponse = useMemo(() => {
    try {
      const response = selectedCannedResponse.generator(responseData)
      setResponseError(null)
      return response
    } catch (err: any) {
      setResponseError(err.message)
      return ""
    }
  }, [selectedCannedResponse, responseData])

  return <Grid container spacing={1}>
    <Grid item xs={12}>
      <Typography variant="h5">
        Canned Responses</Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            size="small"
            label="Response Type"
            fullWidth
            select
            value={selectedCannedResponse.key}
            onChange={handleSelectedCannedResponseChange}
          >
            {cannedResponses.map(cannedResponse =>
              <MenuItem key={cannedResponse.key} value={cannedResponse.key}>
                {cannedResponse.label}
              </MenuItem>
            )}
          </TextField>
        </Grid>
        {selectedCannedResponse.fields.includes('customerName') && (
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Customer Name"
              InputLabelProps={{
                shrink: true,
              }}
              value={responseInputData.customerName}
              onFocus={e => e.target.select()}
              onKeyUp={e => e.key === "Escape" && handleResponseInputChange('customerName', "")}
              onChange={e => handleResponseInputChange('customerName', e.target.value)}
            />
          </Grid>
        )}
        {selectedCannedResponse.fields.includes('missingProducts') && (
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Missing Products"
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              rows={6}
              value={responseInputData.missingProducts}
              onFocus={e => e.target.select()}
              onKeyUp={e => e.key === "Escape" && handleResponseInputChange('missingProducts', "")}
              onChange={e => handleResponseInputChange('missingProducts', e.target.value)}
            />
          </Grid>
        )}
        {selectedCannedResponse.fields.includes('shippingOptions') && (
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Shipping Options"
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              rows={6}
              value={responseInputData.shippingOptions}
              onFocus={e => e.target.select()}
              onKeyUp={e => e.key === "Escape" && handleResponseInputChange('shippingOptions', "")}
              onChange={e => handleResponseInputChange('shippingOptions', e.target.value)}
            />
          </Grid>
        )}
        {selectedCannedResponse.fields.includes('couponCode') && (
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Coupon Code"
              InputLabelProps={{
                shrink: true,
              }}
              value={responseInputData.couponCode}
              onFocus={e => e.target.select()}
              onKeyUp={e => e.key === "Escape" && handleResponseInputChange('couponCode', "")}
              onChange={e => handleResponseInputChange('couponCode', e.target.value)}
            />
          </Grid>
        )}
        {selectedCannedResponse.fields.includes('couponValue') && (
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Coupon Value"
              InputLabelProps={{
                shrink: true,
              }}
              value={responseInputData.couponValue}
              onFocus={e => e.target.select()}
              onKeyUp={e => e.key === "Escape" && handleResponseInputChange('couponValue', "")}
              onChange={e => handleResponseInputChange('couponValue', e.target.value)}
            />
          </Grid>
        )}
        {selectedCannedResponse.fields.includes('trackingNumber') && (
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Tracking Number"
              InputLabelProps={{
                shrink: true,
              }}
              value={responseInputData.trackingNumber}
              onFocus={e => e.target.select()}
              onKeyUp={e => e.key === "Escape" && handleResponseInputChange('trackingNumber', "")}
              onChange={e => handleResponseInputChange('trackingNumber', e.target.value)}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs>
              {responseError && (
                <Typography variant="body2">
                  {responseError}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <IconButton
                onClick={handleClearClick}
              ><Delete /></IconButton>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCopyClick}
              >
                Copy</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs>
      <TextField
        variant="standard"
        size="small"
        multiline
        fullWidth
        label="Copy Response Below"
        InputLabelProps={{
          shrink: true,
        }}
        inputRef={responseInputRef}
        value={cannedResponse}
        onChange={e => { }}
        onFocus={e => e.target.select()}
      />
    </Grid>
  </Grid>
}