import React, { useState } from 'react'
import { Container, Typography } from '@material-ui/core'


type BannerDef = {
  dataUri: string
  filename: string
  error: boolean
}

export default function Banners() {
  const [outputBanners, setOutputBanners] = useState<BannerDef[]>([])
  return <Container>
    <Typography variant="h3">Banners</Typography>
    <div>
      {outputBanners.map(banner =>
        <div key={banner.filename}>
          <div>
            <img
              src={`data:${banner.dataUri}`}
              alt="" />
          </div>
          <div>
            <a
              download={banner.filename}
              href={banner.dataUri}>
              {banner.filename}
            </a>
          </div>
        </div>
      )}
    </div>
  </Container>
}