import React, { useCallback, useState } from 'react'
import axios from 'axios'
import { TextField, Grid, Container, Button, Typography, Checkbox, FormControlLabel } from '@material-ui/core'

import { login, loginInfo } from '../../lib/auth'



export default function LoginForm() {
  const [usernameInput, setUsernameInput] = useState("")
  const [passwordInput, setPasswordInput] = useState("")
  const [rememberInput, setRememberInput] = useState(false)

  const testProtectedRoute = useCallback(async () => {
    try {
      const res = await axios.get('/api/test', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${loginInfo.jwt}`,
        }
      })
      console.log(res)
    } catch (err) {
      console.log("Error testing route")
      console.log(err)
    }
  }, [])

  const handleLoginClick = useCallback(() => {
    login(usernameInput, passwordInput, rememberInput)
  }, [passwordInput, rememberInput, usernameInput])

  return <div>
    <Container maxWidth="xs">
      <Grid container spacing={1} direction="column">
        <Grid item>
          <Typography variant="h5">
            Hello.
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            size="small"
            label="Username"
            fullWidth
            value={usernameInput}
            onChange={e => setUsernameInput(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            size="small"
            label="Password"
            fullWidth
            type="password"
            value={passwordInput}
            onChange={e => setPasswordInput(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid item>
        <FormControlLabel control={
          <Checkbox checked={rememberInput} onChange={e => setRememberInput(e.target.checked)} />
        }
          label="Remember Me"
        />
      </Grid>
      <Grid item>
        <Button onClick={handleLoginClick}>Login</Button>
        <Button onClick={testProtectedRoute}>Test</Button>
      </Grid>
    </Container>
  </div>
}