import React, { useState, useMemo, useCallback, useRef } from 'react'
import { TextField, Button, Typography, Grid, Chip, makeStyles, IconButton } from '@material-ui/core'
import { ErrorOutline, Delete } from '@material-ui/icons'

import { inputStringToList } from '../../../lib/util'
import { popOpenOrders } from '../../../lib/onlineOrders'

const useStyles = makeStyles(theme => ({
  chips: {
    '& > *': {
      margin: theme.spacing(0.25)
    }
  }
}))

export default function PopOpener() {
  const classes = useStyles()
  const orderInputRef = useRef<HTMLInputElement>(null)
  const [orderInput, setOrderInput] = useState("")
  const [prefixInput, setPrefixInput] = useState("")

  const orderNums = useMemo(() => inputStringToList(orderInput), [orderInput]).map(n => prefixInput + n)

  const handleOpenClick = useCallback(
    () => popOpenOrders(orderNums),
    [orderNums])

  const handlePrefixKeyUp = useCallback((e: React.KeyboardEvent) => {
    console.log(e.key)
    if (e.key === "Enter") {
      orderInputRef.current?.focus()
    }
  }, [])

  return <Grid container spacing={1}>
    <Grid item xs={12}>
      <Typography variant="h5">
        Pop Open Orders
      </Typography>
      <TextField
        label="Prefix"
        value={prefixInput}
        size="small"
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        onKeyUp={handlePrefixKeyUp}
        onChange={e => setPrefixInput(e.target.value)}
      />
      <TextField
        variant="outlined"
        size="small"
        margin="dense"
        multiline
        fullWidth
        label="Order Numbers"
        InputLabelProps={{
          shrink: true,
        }}
        rows={5}
        inputRef={orderInputRef}
        value={orderInput}
        helperText={`${orderNums.length} order${orderNums.length === 1 ? '' : 's'}`}
        placeholder="eg. 123 456 789 or 123,456,789 or 123;456;789"
        onChange={e => setOrderInput(e.target.value)}
      />
    </Grid>
    <Grid item xs></Grid>
    <Grid item>
      <IconButton
        onClick={e => setOrderInput("")}
      ><Delete /></IconButton>
      <Button
        onClick={handleOpenClick}
        variant="outlined"
        color="primary"
      >Open</Button>
    </Grid>
    <Grid item xs={12}>
      <div className={classes.chips}>
        {orderNums.map((orderNum, i) => {
          const duplicate = orderNums.indexOf(orderNum) !== i
          const invalid = !/^[0-9]+$/.test(orderNum)
          const title = invalid ? "Invalid" : duplicate ? "Duplicate" : ""
          return <Chip
            variant="outlined"
            size="small"
            color={duplicate || invalid ? "secondary" : "default"}
            title={title}
            icon={duplicate || invalid ? <ErrorOutline /> : undefined}
            onClick={e => popOpenOrders([orderNum])}
            key={`${orderNum}-${i}`}
            label={orderNum}
          />
        }
        )}
      </div>
    </Grid>
  </Grid >
}