import React from 'react'
import { AppBar as MuiAppBar, Toolbar, Button } from '@material-ui/core'
import { NavLink } from 'react-router-dom'


export default function AppBar() {
  return <MuiAppBar position="static">
    <Toolbar>
      <Button component={NavLink} to="/onlineOrders" color="inherit">
        Online Orders</Button>
      <Button component={NavLink} to="/productPhoto" color="inherit">
        Product Photo</Button>
      <Button component={NavLink} to="/banners" color="inherit">
        Banners</Button>
      <Button component={NavLink} to="/priceChange" color="inherit">
        Price Change</Button>
    </Toolbar>
  </MuiAppBar>
}