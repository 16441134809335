import React from 'react'
import './App.css'
import Main from './components/Main'

function App() {
  return <div className="App">
    <Main />
  </div>

}

export default App
