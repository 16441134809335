import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { CssBaseline } from '@material-ui/core'

import AppBar from './AppBar'
import AppRoutes from './AppRoutes'


export default function Main() {
  return <>
    <CssBaseline>
      <Router>
        <AppBar />
        <AppRoutes />
      </Router>
    </CssBaseline>
  </>
}