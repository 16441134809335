import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import Login from "./Routes/Login"
import BulkPriceChange from "./Routes/BulkPriceChange"
import ProductPhoto from "./Routes/ProductPhoto"
import OnlineOrders from "./Routes/OnlineOrders"
import Banners from "./Routes/Banners"


export default function AppRoutes() {
  return <Routes>
    <Route path="/login" element={<Login />} />
    <Route path="/priceChange" element={<BulkPriceChange />} />
    <Route path="/productPhoto" element={<ProductPhoto />} />
    <Route path="/onlineOrders" element={<OnlineOrders />} />
    <Route path="/banners" element={<Banners />} />
    <Route path="/" element={<Navigate to="/onlineOrders" />} />
  </Routes>
}